<template>
  <!-- <div class="anno-root">
    <div style="height: 35px"></div>
    <div class="anno-content">
      <div class="anno-list">
        <div v-for="(item,index) in dataList" :key="index">
          <div class="anno-list-item">
            <div class="anno-list-item-content">
              <div class="titleStyle">{{ item.title }}</div>
              <div class="contentStyle">{{ item.content }}</div>

            </div>
            
            <div class="anno-list-item-date">{{ formatCreateTime(item.create_time) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="anno-root1">
    
    <!-- <div class="tipClass">
      23-24学年FELC全国高校“商科学术”竞赛和“企业技能”竞赛成绩预计5月31日公布，请各位同学耐心等待。
    </div> -->
    <el-row>
      <el-col :span="8">
        <div class="contentDiv">
          
          <div class="contentTitleDiv">设备配置说明</div>

          <div class="contentInfoDiv">
            为了保证考试的顺利，请使用：<br>
            <span style="color: #00348b;font-weight: 500;">・ 带摄像头和麦克风的电脑（不能使用平板）</span> <br>
            <span style="color: #00348b;font-weight: 500;">・ 最新版的Chrome浏览器 </span> <br>
            并按照<span style="color: red;text-decoration: underline;font-weight: 500;cursor: pointer;" @click="gotoDetail()" >考试设备指南</span>对电脑和浏览器进行配置。<br><br>

            未能正确配置设备可能导致无法进入考试、无法
            完整的使用考试系统功能或者考试中断等异常情
            况，导致考试失败。
          </div>

          


        </div>
      </el-col>
      <el-col :span="8">
        <div class="contentDiv">
          <div class="contentTitleDiv">身份验证说明</div>

          <div class="contentInfoDiv">
            为核验考生身份，所有选手需提前准备身份证明，在进入考试时进行拍照检查（证件照片仅做监考检查使用，考试核分结束后5个工作日后从服务器删除）。<br><br>
            身份证明指任何带有本人照片和姓名信息的官方证明文件（包括但不限于<span style="color: #00348b;font-weight: 500;">身份证、护照、驾照、学校学生证、社保卡</span>或其他证明文件）。
            
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="contentDiv">
          <div class="contentTitleDiv">线上监考说明</div>

          <div class="contentInfoDiv">
            为保证考试的公平和公正，FELC比赛系统将通过摄像头和屏幕共享功能自动监考并录制监考视频同时，将有监考员监视后合实时监考。<br><br>考试过程中不允许切换电脑界面/最小化或切换浏览器，以上行为将被系统判定切屏；组委会将在考试结束后对系统提示异常的切屏画面进行人工核查。
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {getHomeAnnouncements} from '../../api/index'
import {formatDate} from '../../utils/date'
import '../../assets/common/font.css'

export default {
  name: "index",
  data() {
    return {
      dataList: []
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    formatCreateTime(create_time) {
      var time = JSON.parse(create_time) * 1000
      var temp_date = new Date(time)
      return formatDate(temp_date, 'yyyy/MM/dd')
    },
    gotoDetail(){
      window.open('https://mainbucket.learningfirst.cn/FELC%E3%80%8A%E8%AE%BE%E5%A4%87%E6%8C%87%E5%8D%97%E3%80%8B.pdf','_blank')
    },
    fetchData() {
      getHomeAnnouncements().then((res) => {
        var result = res.data.data
        for (var i = 0; i < result.length; i++) {
          this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

.anno-list-item-content {
  flex: 1;
  margin-right: 10px;
  font-family: Montserrat-SemiBold;
  color: #00348b;
  font-size: 26px;
  // font-weight: bold;
}
.titleStyle{
  font-family: Montserrat-SemiBold;
  font-size: 16px;

}
.contentStyle{
  margin-top: 10px;
  font-family: Montserrat-SemiBold;
  font-size: 16px;

}
.anno-list-item-date {
  margin-right: 10px;
  font-family: Montserrat-SemiBold;
  color: #00348b;
  font-size: 20px;
  font-weight: bold;
}

.anno-list-item {
  margin-top: 20px;
  width: 100%;
  padding-bottom: 8px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #00348b;
  display: flex;
  flex-direction: row;
}

.anno-root {
  margin: 0 auto;
  width: 1100px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.anno-content {
  flex: 1;
  width: 100%;
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.anno-list {
  padding-left: 40px;
  padding-right: 40px;
}

//new
.anno-root1 {
  margin: 0 auto;
  // width: 1100px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 5vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  // background-color: #00348b;
}
.contentDiv{
  margin-left: 10%;
  width: 80%;
  height: 50vh;
  border: 1px solid #00348b;
}

.contentTitleDiv{
  width: 100%;
  height: 30px;
  color: #333;
  text-align: center;
  line-height: 30px;
  font-size: 25px;
  font-weight: 500;
  margin-top: 50px;
}
.contentInfoDiv{
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  line-height: 30px;
  word-break: normal;
  word-spacing: normal;
  color: #606266;
  font-size: 15px;
}
.tipClass{
  // background-color: rebeccapurple;
  text-align: center;
  font-size: 20px;
  margin-bottom: 40px;
}
</style>